<template>
  <div>
    <el-row :gutter="20">
      <el-col >
        <div style="padding:0 10px;margin-left: 10px;max-height: 350px;overflow: auto;">
          <spread-table :smallType="true" v-loading="loading" :tableHeight="800" :pageSize="pageSize" :currentPage="currentPage" :total="total" @sizeChange="sizeChange" @currentChange="currentChange" :tableColumn="tableList" :tableData="warnTableData"></spread-table>
        </div>
      </el-col>
      <div style="width: 100%;padding-right: 20px;margin-bottom: 20px;">
        <Market :tableHeight="800"></Market>
      </div>
    </el-row>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import SpreadTable from '@/components/SpreadTable'
import Market from '@/components/MarketQuotations'
import { summaryData } from "./summaryData";
import { currentChanges, sizeChanges, getWarnLists } from "./summaryMethods";

export default defineComponent({
  name: "index",
  components: {
    Market,
    SpreadTable,
  },
  setup() {
    onMounted(() => {
      getWarnList()
    })
    let data = reactive(summaryData)
    let getWarnList = () => { getWarnLists(data) }
    let sizeChange = (val) => { sizeChanges(val, data) }
    let currentChange = (val) => { currentChanges(val, data) }
    return {
      ...toRefs(data),
      sizeChange,
      currentChange,
    }
  }
})
</script>

<style scoped>
</style>