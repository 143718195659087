export const summaryData = {
    tableList : [
        {name:'警告类型',text: 'wnTypeName'},
        {name:'发生日期',text: 'date'},
    ],
    currentPage:1,
    pageSize: 5,
    total: 0,
    loading: true,
    warnTableData:[],
}